<template>
  <standard-page title="Claim Requests" :definition="$DEFINITIONS.claimRequests">
    <template v-slot:breadcrumb>
      <span>
        <router-link :to="{ name: 'identities-home'}">Identifier</router-link>
      </span>
      <span>
        <router-link :to="{ name: 'claim-requests'}">Claim Requests</router-link>
      </span>
      <span v-if="short_name">DID: {{short_name | truncate(40, '.....')}}</span>
    </template>
    <template v-slot:sub-title>
      <p class="mg-b-10">
        <a href="javascript:void(0);" v-clipboard:copy="did" v-clipboard:success="copied">
          {{did | did}}
        </a>
      </p>
    </template>
    <template v-slot:content>
      <div class="az-content-body az-content-body-profile">
        <nav class="nav az-nav-line">
          <a :class="{active: activeTab === 0}" @click="navigate(0)" class="nav-link" data-toggle="tab" href="#account">DID Document</a>
          <a :class="{active: activeTab === 1}" @click="navigate(1)" class="nav-link" data-toggle="tab" href="#wallet">Claims</a>
        </nav>
        <div class="az-tab-content">
          <div :class="{'d-none': activeTab !== 0}" id="record">
            <properties-of-requested-did :did="did" />
          </div><!-- az-profile-body -->
          <div :class="{'d-none': activeTab !== 1}" id="claims">
            <claim-of-requested-did :catalog="catalog" :did="did" />
          </div><!-- az-profile-body -->
        </div>
      </div>
    </template>
  </standard-page>
</template>

<script>
import PropertiesOfRequestedDid from "./PropertiesOfRequestedDid";
import ClaimOfRequestedDid from "./ClaimsOfRequestedDid";

export default {
  name: "ViewRequestedDid",
  props: ['catalog', 'did', 'short_name'],
  components: { ClaimOfRequestedDid, PropertiesOfRequestedDid },
  data() {
    return {
      activeTab: 1
    };
  },
  methods: {
    navigate(index) {
      this.activeTab = index;
    },
    copied() {
      this.$toastr.i("Copied to clipboard", "Copied");
    }
  }
}
</script>

<style scoped>
</style>