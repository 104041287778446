<template>
  <div>
      <div class="row row-sm mg-t-20 mg-b-20">
          <div class="col-8">
              <p v-html="$DEFINITIONS.claim"></p>
          </div>
          <div class="col-4">
              <router-link :to="{name: 'newclaim', params: {catalog: catalog, did: did }}" class="d-none float-right d-xl-block btn btn-orange rounded font-weight-bold" title="Add Claim">
                <i class="fas fa-plus-circle mg-r-10" />Add Claim
              </router-link>
              <router-link :to="{name: 'newclaim', params: {catalog: catalog, did: did }}" class="d-xl-none btn btn-icon float-right btn-orange rounded w-35" title="Add Claim">
                  <i class="typcn typcn-document-add" />
              </router-link>
          </div>
      </div>

    <data-table tableId="claims-table" :api="api" :columns="columns" @ready="onReady" list-key="claims"></data-table>

    <view-attestation-modal :claimid="claim_id" :did="did" v-if="claim_id >= 0"></view-attestation-modal>
  </div>
</template>

<script>

import ViewAttestationModal from "../../catalogs/actions/dids/actions/claim/ViewAttestationModal";

export default {
  name: "ClaimOfRequestedDid",
  components: { ViewAttestationModal },
  props: ['catalog','did'],
  data() {
    return {
      api: `${this.$identity.defaults.baseURL}/identity/dids/${this.did}/claims`,
      columns: [
        { title: 'Claim', data: 'description' },
        {
          type: 'statements',
        },
        { type: 'created', title: "Issued On" },
        { type: 'extrinsic' },
        { title: 'Status', data: 'current_attestations_count', render: data => data > 0 ? `<span class="attested">Attested</span>` : `<span class="notattested">Not attested</span>` },
        {
          type: 'action',
          data: 'attestations_count',
          render: data => {
              return `
              <div class="btn-group btn-icon-list">
                                <span class="text-decoration-none text-info view_claim mg-l-10" style="cursor: pointer">
                                    <i class="fas fa-eye fa-lg" title="View Claim Attachments & Attestations"></i>
                                </span>
                          </div>
          `;
          }
        },
      ],
      table: null,
      claim_id: null,
    };
  },

  methods: {

    onReady(table) {
      this.table = table;
      let self = this;

      $(async function () {
        $('#claims-table tbody').on('click', '.view_claim', function () {
          let entity = table.row($(this).parents('tr')).data();
          self.setClaimId(entity.claim);
        });

      });
    },


    setClaimId(claimid) {
      this.claim_id = claimid;
        this.$router.push({name: 'view-claim-for-consumer', params: { did: this.did, claimid:  claimid}})
    },

  }
}
</script>

<style scoped>
.btn-orange {
  color: white !important;
}
.attested,
.notattested {
  border-radius: 5px;
  padding: 2px 7px;
  color: white;
  font-weight: 500;
}
.attested {
  background-color: green;
}
.notattested {
  background-color: red;
  white-space: nowrap;
}
</style>