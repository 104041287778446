<template>
  <div>
    <p class="mg-b-10 mg-t-20" v-html="$DEFINITIONS.didDocument"></p>
    <data-table :api="api" :columns="columns" list-key="property_list"></data-table>
  </div>
</template>

<script>

export default {
  name: "PropertiesOfRequestedDid",
  props: ['did'],
  data() {
    return {
      api: `${this.$identity.defaults.baseURL}/identity/dids/${this.did}/properties`,
      columns: [
        { type: 'name' },
        { type: 'fact' },
        { type: 'private' },
        { type: 'created' },
        { type: 'extrinsic' }
      ],

    };
  },

}
</script>

<style scoped>
</style>